.container
    display: grid
    grid-template-areas: "line line" "socl legl" "botm botm"
    grid-template-columns: 1fr 1fr
    grid-gap: 40px 20px
    padding-bottom: 42px
    @media (max-width: 1100px)
        background-color: #232323
        margin-top: 0
    @media (max-width: 580px)
        grid-template-areas: "line" "socl" "legl" "botm"
        grid-template-columns: 1fr

.footerLine
    grid-area: line
    border: 1px solid rgba(255, 255, 255, 0.3)
    height: 0
    margin-bottom: -10px

.socialLinks
    grid-area: socl
    display: flex
    & > a
        padding: 0 0.5rem
        &:not(:last-child)
            margin-right: 2rem

.textLink
    color: #fff
    font-weight: 500
    font-size: 1rem
    line-height: 1.5rem
    text-decoration: none
    border: 0
    transition: color 150ms ease-in-out
    padding: 0
    &:hover
        color: #62e479

.legalLinks
    grid-area: legl
    display: flex
    justify-self: end
    @media (max-width: 1100px)
        flex-direction: column
    @media (max-width: 580px)
        justify-self: start

.legalButton
    background-color: transparent
    border: none
    padding: 0
    font-weight: 500
    font-size: 1rem
    line-height: 1.5rem
    color: #fff
    white-space: nowrap
    transition: color 150ms ease-in-out
    @media (max-width: 1100px)
        align-self: flex-end
    @media (max-width: 580px)
        align-self: flex-start
    &:not(:last-child)
        margin-right: 3rem
        @media (max-width: 1100px)
            margin-bottom: 1rem
            margin-right: 0
    &:hover
        color: #62e479

.footerBottom
    grid-area: botm
    font-weight: 500
    color: #989b97
    @media (max-width: 580px)
        text-align: center

.legalModal
    max-height: 75vh

.legalInfoWrapper
    font-weight: 500
    font-size: 17px
    line-height: 21px
    text-align: left
    @media (min-width: 375px)
        width: 336px
    @media (min-width: 851px)
        width: 740px
    @media (min-width: 1401px)
        width: 1000px
    & > h1
        margin-bottom: 30px   
    & p
        font-weight: 500
        font-size: 17px
        line-height: 21px
        margin-bottom: 30px
        &:last-child
            margin-bottom: 0
    & ol
        padding-left: 0
    & a
        font-weight: 500
        font-size: 17px
        line-height: 21px
    & > p > label
        outline: none

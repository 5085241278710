.notificationWindow
    padding: 48px 44px
    width: 500px
    min-height: 240px
    background: linear-gradient(0deg, #232323, #232323), linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))
    position: fixed
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    min-width: 350px
    z-index: 3
    border-radius: 8px
    max-height: 100vh
    overflow-y: auto
    @media (max-width: 1100px)
        max-width: 800px
        width: 100%
        min-width: initial

.modalOverlay
    position: fixed
    top: -50vh
    left: -50vw
    width: 200vw
    height: 200vh
    background: rgba(0, 0, 0, 0.7)
    backdrop-filter: blur(10px)
    z-index: 2

.container
    display: flex
    justify-content: center
    align-items: center
    position: fixed
    padding-top: 56px
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background: #000000b3
    backdrop-filter: blur(10px)
    z-index: 2

.bodyWrapper
    display: flex
    flex-direction: column
    position: relative
    padding: 50px
    background: #2e2e2e
    z-index: 3
    border-radius: 8px
    max-height: 100%
    @media (max-width: 580px)
        padding: 50px 20px 20px

.title
    font-size: 34px
    font-weight: 500
    line-height: 42px
    text-align: left
    margin-bottom: 30px
    color: #fff
    @media (max-width: 1100px)
        font-size: 28px

.contentWrapper
    overflow-x: hidden
    overflow-y: auto

.closeButton
    display: block
    position: absolute
    top: 0
    right: 0
    padding: 8px
    margin-right: 8px
    margin-top: 8px
    background: none
    border: none
    cursor: pointer
    & > img
        width: 24px

$bgColor: rgba(18, 18, 18, 1)

.container
    display: grid
    grid-template-columns: 1fr 2fr 1fr
    align-items: center
    justify-items: center
    background: $bgColor
    min-height: var(--navbar-height)
    margin: 0
    padding: 0
    position: sticky
    top: 0
    z-index: 3
    padding-left: 72px
    @media (max-width: 1050px)
        padding-left: 50px
    @media (max-width: 800px)
        grid-template-columns: min-content 1fr 1fr
        padding-left: 20px

.hamburgerButton
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border: none
    background-color: transparent
    height: 40px
    width: 40px
    margin: 0
    padding: 0
    z-index: 3
    margin-right: 20px
    @media (min-width: 801px)
        display: none
    &.active
        & > .topLine
            transform: translateY(8px) rotate(45deg)
        & > .centerLine
            width: 3px
        & > .bottomLine
            transform: translateY(-8px) rotate(-45deg)

%buttonLine
    display: flex
    background-color: rgba(152, 155, 151, 1)
    width: 24px
    height: 3px
    border-radius: 30px
    transition: transform 200ms ease-in-out, width 200ms ease-in-out
    &:not(:last-child)
        margin-bottom: 5px

.topLine,
.centerLine,
.bottomLine
    @extend %buttonLine

.logoLink
    z-index: 3
    justify-self: left
    & > img
        width: 117px
        height: 32px

.solidBackdrop
    position: absolute
    top: 0
    width: 100%
    height: 56px
    background-color: $bgColor
    z-index: 2
    @media (min-width: 801px)
        display: none

.navLinksList
    @media (min-width: 801px)
        & > li
            &:nth-last-child(n+2)
                margin-right: 10px
    @media (max-width: 800px)
        position: absolute
        top: -100%
        z-index: 1
        opacity: 0
        visibility: hidden
        transition: opacity 200ms ease-in-out, top 200ms ease-in-out
        display: flex
        flex-direction: column !important
        width: 100%
        background-color: $bgColor
        & > li > a
            padding: 15px 20px !important
        &.active
            visibility: visible
            top: 100%
            opacity: 1

.userControlsList
    display: flex
    flex-direction: row !important
    justify-self: right
    z-index: 3
    &.hidden
        @media (max-width: 580px)
            display: none

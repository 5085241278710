/*html, body, #root {*/
/*    margin: 0;*/
/*    height: 100%;*/
/*}*/

html, body {
  height: 100%; /* Nustato HTML ir BODY aukštį iki viso ekrano aukščio */
  margin: 0; /* Pašalina numatytąjį naršyklės paraštę */
  display: flex; /* Įjungia flexbox rodymo režimą */
  flex-direction: column; /* Nustato flex elementus vertikaliai */
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App {
  flex: 1;
}

.App.sign-up, .App.conditions, .App.status {
  background-color: #1F1F1F;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  background-color: #0B0B0B;
}

h1 {
  font-weight: 500;
  font-size: 34px;
  line-height: 36px;
  margin-bottom: 0;
}

.body-background {
  position: fixed;
  top: 128px;
  left: 0;
  width: 100%;
  height: calc(100vh - 128px);
  background-image: none;
  background-size: cover;
  background-color: #0B0B0B;
  z-index: -1;
}

.body-background.cover {
  background-image: url('../img/black-cover-image.png');
}

.navbar-expand-md .navbar-nav .nav-link {
  padding: 5px 16px;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(224, 224, 224, 1);
}

a {
  color: rgba(33, 177, 75, 1);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: rgba(33, 177, 75, 1);
}

.hidden {
  visibility: hidden;
}

.inline-element {
  display: inline;
}

/*.capitalize-first-letter::first-letter {*/
/*  text-transform: uppercase;*/
/*}*/

.main-wrapper {
  height: calc(100% - 56px);
  overflow-x: hidden;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*.description .line-clamp-3 {*/
/*  display: -webkit-box !important;*/
/*  -webkit-line-clamp: 3 !important;*/
/*  -moz-box-orient: vertical !important;*/
/*  overflow: hidden !important;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

.inline1, .inline2, .inline3 {
  display: inline;
}

@media (min-width: 768px) {
  /*.col-epglist, .col-epgdetail {*/
  /*    float: left;*/
  /*}*/

  /*.col-genres {*/
  /*  max-width: 18%;*/
  /*  flex: 0 0 18%;*/
  /*}*/

  .col-channels {
    position: relative;
    width: 13.5%;
    margin-right: 3.437%;
    /*flex: 0 0 12%;*/
  }

  .col-epglist {
    width: 47.313%;
    margin-right: 3.437%;
    /*flex: 0 0 40%;*/
  }

  .col-epgdetail {
    width: 32.5%;
    /*flex: 0 0 30%;*/
  }
}

.row {
  margin: auto;
}

.dropdown-icon {
  padding-right: 5%;
}

/*.container-fluid {*/
/*  padding: 0;*/
/*}*/

.navbar {
  background: rgba(18, 18, 18, 1);
  min-height: var(--navbar-height);
  margin: 0;
  padding: 0;
  align-items: flex-start;
  z-index: 3;
  position: sticky;
  top: 0;
}

.scrollable-column {
  max-height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: var(--navbar-height);
  background-color: #2E2E2E;
  /*width: 18.4%;*/
  min-width: 322px;
  font-size: 1rem;
}

:root {
  --navbar-height: 56px;
  /*--bs-progress-bar-bg: green;*/
}

.navbar-nav.home-icons {
  margin-right: 20px;
  padding-right: 20px;
}

/*@media (max-width: 767px) {*/
/*  .nav-item {*/
/*    padding-top: 5px;*/
/*    padding-bottom: 5px;*/
/*  }*/
/*}*/

.nav-link {
  padding: 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 62px;
}

.navbar-nav .nav-link:hover, .footer-content .nav-link:hover {
  color: #62E479;
}

.navbar .login-btn {
  width: 154px;
  height: 56px;
  background-color: #21B14B;
  font-size: 14px;
  letter-spacing: 0.078125rem;
  position: absolute;
  top: 24px;
  right: 16px;
  line-height: 56px;
  padding: 0 !important;
  margin: 0 !important;
}

.small-img {
  height: auto;
  border-radius: 8px;
}

.spinning-arrow {
  margin-left: 80%;
}

.movie-list-column {
  height: calc(100vh - 146px);
}

.overflow {
  overflow-y: auto;
}

.category-list {
  list-style: none;
  text-align: center;
  padding: 0;
}

.category-list-element {
  margin: 10px 0;
}

.category-text {
  display: inline-block;
  padding: 0.7rem 1.5rem;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  border-radius: 40px;
  transition: background-color 0.3s ease;
}

.category-text.active {
  background-color: #505050;
}

.category-text:hover {
  background-color: #4B4B4B;
  color: #fff;
}

.channel-logo {
  opacity: 0.5;
}

.channel-logo:hover {
  opacity: 1;
}

.active .channel-logo {
  opacity: 1;
}

.channel-list {
  padding: 0;
}

/*.channel-list-element {*/
/*  margin: 20px auto 0 auto;*/
/*  width: 90px;*/
/*}*/

.date-text {
  font-weight: normal;
  color: #B1B1B1;
  padding: 10px;
  width: 110px;
  text-align: center;
}

/*a.date-text:hover {*/
/*  text-decoration: none;*/
/*  color: #FFFFFF;*/
/*  font-weight: bold;*/
/*}*/

/*a.date-text.active:hover {*/
/*  text-decoration: none;*/
/*  color: #62E479;*/
/*}*/

.date-container {
  display: flex;
  align-items: center;
  background-color: #3C3C3C;
  margin-right: 10px;
  border-radius: 8px;
}

.date-container:hover {
  background-color: #5B5B5B;
}

.date-container a:hover {
  color: white;
}

.date-container.active {
  background-color: #34A241;
}

.date-container.active a {
  color: white;
}

/*.movies-text {*/
/*  font-weight: normal;*/
/*  font-size: 20px;*/
/*  line-height: 24px;*/
/*  color: #808080;*/
/*  margin: 0;*/
/*}*/

/*.movies-text-late {*/
/*  font-weight: normal;*/
/*  font-size: 20px;*/
/*  line-height: 24px;*/
/*  margin: 0;*/
/*}*/

.recent-dates {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  /*background-color: #000000;*/
  /*height: 78px;*/
  /*overflow: hidden;*/
  margin-bottom: 16px;
  /*border-radius: 10px;*/
}

/*.recent-dates .date-container:last-child > span {*/
/*  display: none;*/
/*}*/

.recent-dates-cover {
  position: absolute;
  height: 100%;
  min-width: 100%;
  pointer-events: none;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 15%,
                    rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0.7) 100%);
}

.movie-list {
  position: relative;
  z-index: 1;
}

/*.movie-list-cover {*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  z-index: 1;*/
/*  pointer-events: none;*/
/*  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 10%,*/
/*                    rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 1) 100%);*/
/*}*/

.movies {
  position: relative;
}

.movie-list-time {
  position: absolute;
  left: 6%;
}

.movie-list-title {
  overflow: hidden;
  white-space: nowrap;
}

.movie-list-icon {
  position: absolute;
  left: 20px;
  width: 2rem;
  height: 2rem;
  background-image: url('../img/play-icon.svg');
 }

.movie-list-icon-disabled {
  background-image: url('../img/dash-circle.svg');
 }
 
.movie-row {
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.movie-row:hover {
  background-color: #343434;
}

.movie-row.active {
  border-width: 1px;
  border-style: solid;
  border-color: #34A241;
  background-color: #29362B;
}

.movie-row p {
  margin-bottom: 0;
  color: #989B97;
}

.movie-row.late p {
  color: white;
}

/*.movie-link.active .movie-row .movie-list-icon {*/
/*  filter: invert(55%) sepia(14%) saturate(2535%) hue-rotate(85deg) brightness(97%) contrast(84%);*/
/*}*/

/*.movie-link.active .movie-row:hover .movie-list-icon {*/
/*  filter: none;*/
/*}*/

/*.movie-link.active .movie-row .notification-icon {*/
/*  filter: invert(55%) sepia(14%) saturate(2535%) hue-rotate(85deg) brightness(97%) contrast(84%);*/
/*}*/

/*.movie-link.active .movie-row:hover .notification-icon {*/
/*  filter: none;*/
/*}*/

/*.movie-link.active .movie-row .record-icon {*/
/*  filter: invert(55%) sepia(14%) saturate(2535%) hue-rotate(85deg) brightness(97%) contrast(84%);*/
/*}*/

/*.movie-link.active .movie-row:hover .record-icon {*/
/*  filter: none;*/
/*}*/

/*.movie-link.active .movie-row .movies-text,*/
/*.movie-link.active .movie-row .movies-text-late,*/
/*.movie-link.active .movie-row .movies-text-now {*/
/*  color: #20B149;*/
/*}*/

/*.movie-link .movie-row:hover .movies-text,*/
/*.movie-link .movie-row:hover .movies-text-late,*/
/*.movie-link .movie-row:hover .movies-text-now {*/
/*  color: #FFFFFF;*/
/*}*/

.notification-icon {
  background-image: url('../img/notification-icon.svg');
}

.notification-icon.late {
  display: none;
}

.epg-list-live {
  background-image: url('../img/epg-list-live.svg');
}

/*.movie-link .movie-row .record-icon-late,*/
/*.movie-link .movie-row .record-icon-now {*/
/*  display: none;*/
/*}*/

.movie-preview {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.movie-description {
  padding: 16px;
  border-radius: 8px;
  background-color: #2E2E2E;
  max-width: 50%;
}

.epg-description {
  border-radius: 8px;
  background-color: #2E2E2E;
}

.text-dim {
  color: #ADADAD;
}

.text-bright {
  color: white;
}

.btn-danger {
  background-color: #EA2121;
}

.link-btn {
  background-color: #484848;
}

.link-btn:hover {
  background-color: #484848;
  opacity: 0.9;
}

/*.tv .button > img, .shop .button > img {*/
/*  position: absolute;*/
/*  left: 10px;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*  width: 2em;*/
/*  height: 2em;*/
/*}*/

/*.tv .record-icon {*/
/*  width: 26px;*/
/*  height: 26px;*/
/*}*/

.button-text {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.078125rem;
  position: absolute;
  left: 30%;
  text-transform: uppercase;
}

.cgates {
  padding-right: 12%;
}

.search-info {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.009375rem;
}

.form-control {
  font-weight: 400;
  font-size: 16px;
  color: inherit;
  padding: 0;
  border: none;
  height: 24px;
  background: transparent;
}

.inside-label {
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  color: rgba(202, 196, 208, 1);
}

label.form-check-label {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
  /*padding-top: 13px;*/
  padding-left: 8px;
}

.form-control:focus {
  background: transparent;
  border: none;
  color: inherit;
  box-shadow: none;
}

/*.form-check {*/
/*  margin-top: 16px;*/
/*}*/

.form-check-input {
  min-width: 48px;
  height: 48px;
  margin-top: 0;
  vertical-align: top;
  background-size: contain;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-print-color-adjust: exact;
}

.form-check-input[type="checkbox"]:focus {
  border: none;
  outline: none;
}

.form-check-input:focus {
  border: none;
  outline: none;
}

.form-check-input[type="checkbox"] {
  border: none;
  background-image: url('../img/check-icon.svg');
  background-color: transparent;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url('../img/check-icon-on.svg');
}

.form-check-input:active[type="checkbox"] {
  background-image: url('../img/check-icon-press.svg');
}

.form-check-input:checked:focus[type="checkbox"] {
  background-image: url('../img/check-icon-focus.svg');
}

.form-check-input:checked:hover[type="checkbox"],
.form-check-input:checked:focus[type="checkbox"] {
  background-image: url('../img/check-icon-focus.svg');
}

.form-check-input:not(:checked):hover[type="checkbox"],
.form-check-input:not(:checked):focus[type="checkbox"] {
  background-image: url('../img/check-off-icon-focus.svg');
}

input.form-control::placeholder {
  opacity: 0.7;
}

.input-box {
  background-color: rgba(66, 66, 66, 0.8);
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid rgba(66, 66, 66, 0.8);
}

.input-box.pin {
  margin-right: 8px;
}

.input-box.pin input {
  min-height: 48px;
  width: 40px;
  text-align: center;
}

.input-box:hover {
  border: 1px solid #343434;
  background-color: #343434;
}

.input-box.focus {
  border: 1px solid rgba(143, 143, 143, 1);
}

.input-box.has-error {
  border: 1px solid rgba(228, 99, 99, 1);
}

.has-error label, .supporting-text.has-error {
  color: rgba(228, 99, 99, 1);
}

.d-flex.input-box > .w-100 {
  padding: 4px 14px 4px 16px;
}

.grouped-text {
  text-align: center;
}

.rate-it {
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  padding-left: 110px;
}

.rating {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.03em;
  padding-left: 20%;
  opacity: 0.5;
}

.rate-button {
  background-color: #212121;
  height: 196px;
  display: flex;
  align-items: center;
}

.rate-element {
  padding-left: 7px;
  padding-right: 7px;
}

.rating-row {
  margin-left: 103px;
  margin-right: 2%;
}

.thumb-img {
  padding-left: 10%;
}

.nav-line {
  background-color: #212121;
  height: 64px;
  margin: 0 20px 20px 20px;
}

.nav-line-img {
  line-height: 64px;
  padding-left: 2%;
  display: inline;
}

.nav-line-text {
  line-height: 64px;
  font-weight: bold;
  padding-left: 4%;
  display: inline;
}

.header-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.03em;
}

.movie-type {
  font-size: 24px;
  line-height: 29px;
  opacity: 0.5;
}

.column-1 {
  padding-left: 80px;
  padding-right: 15%;
}

.content {
  padding-left: 30px;
}

.no-margin {
  margin: 0;
  padding: 0 !important;
}

.watch-button {
  font-style: normal;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.078125rem;
  height: 56px;
  background-color: #484848;
  border-radius: 50px;
  border-style: none;
  width: 30%;
  margin-right: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.watch-button:focus {
  outline: 0;
}

.imdb-text {
  font-size: 28px;
  line-height: 34px;
  padding-bottom: 10%;
}

.languages-title-text {
  font-size: 24px;
  line-height: 29px;
}

.languages-content-text {
  opacity: 0.3;
}

.age-restriction {
  padding-top: 6%;
}

.border-box {
  border: 4px solid #7e7878;
  border-radius: 16px;
  padding: 20px;
  width: 99px;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.age-restriction-text {
  color: #7e7878;
  font-size: 24px;
  line-height: 29px;  
}

.button-content {
  display: flex;
  padding-top: 5%;
}

.modal {
  padding: 0 !important;
}

.modal-backdrop {
  background: none;
}

.modal-dialog {
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0;
  height: 100vh;
  max-width: 40vw;
}

.modal-content {
  background-color: black;
  border: none;
  height: 100%;
  border-radius: 0;
  overflow: auto;
  padding: 2em;
}

.modal-header {
  /*margin: 32px 32px 0;*/
  height: 64px;
  line-height: 64px;
  border-bottom: none;
  display: flex;
  align-items: center;
}

.language-modal .modal-header {
  padding: 0;
}

.modal-title {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.card {
  background-color: black;
  /*margin-bottom: 10px;*/
}

.card-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.modal-footer {
  border-top: none;
}

.button.btn-link {
 border-radius: 0;
}

.btn-link {
  font-weight: 500;
  color: #989B97;
  text-decoration: none;
  background: transparent;
  border: none;
}

.btn-link:hover {
  color: white;
}

.btn.btn-link:active {
  color: white;
}

.btn-link:focus {
  color: #21B14B;
}

/*.btn-link::a {*/
/*  color: #21B14B;*/
/*}*/

/*.btn-block {*/
/*  font-weight: 500;*/
/*  font-size: 14px;*/
/*  line-height: 17px;*/
/*  letter-spacing: 0.078125rem;*/
/*  text-transform: uppercase;*/
/*  padding: 0;*/
/*  width: 50%;*/
/*  text-align: left;*/
/*  margin-left: 103px;*/
/*}*/

/*.btn-block:hover {*/
/*  color: #62E479;*/
/*}*/

/*.btn-block+.btn-block {*/
/*  margin-top: 25px;*/
/*}*/

.avatar-image {
  padding-right: 25px;
}

.profiles-container {
  height: 27px;
}

.btn-info {
  background: none;
  border: none;
}

.btn-info:hover {
  background: none;
  box-shadow: none;
}

.btn-info:focus {
  background: none;
  box-shadow: none;
}

.btn-info:active {
  background: none !important;
  box-shadow: none !important;
}

.btn {
  padding: 0;
}

/*.labels-text {*/
/*  font-weight: normal;*/
/*  font-size: 14px;*/
/*  line-height: 17px;*/
/*  background-color: black;*/
/*  padding-left: 20px;*/
/*  padding-right: 20px;*/
/*}*/

.confirm-cancel-button {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  border-radius: 50px;
}

.confirm-cancel-row {
  padding-top: 30%;
}

.profile-edit .confirm-cancel-row {
  padding-top: 0;
  position: absolute;
  bottom: 10%;
  width: 100%;
}

.profile-edit .form-group.btn-div {
  position: relative;
  height: 200px;
}

.example-video-image {
  width: 100%;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 5%;
}

.carousel-button-group {
  position: absolute;
  width: 100vw;
  top: 30%;
  /*z-index: 2;*/
}

.carousel-button-group._2_3_medium, .carousel-button-group._2_3_big {
  top: 42%;
}

.carousel-button-group._screenwide {
  top: unset;
}

.carousel-button {
  width: 39px;
  height: 39px;
  /*top: calc(50% - 20px);*/
  background-color: transparent;
  border: none;
}

.carousel-button > img {
  height: 26px;
  opacity: 0.6;
}

.carousel-button > img:hover {
  opacity: 1;
}

.carousel-button:focus {
  outline: none;
}

.carousel-button-left {
  position: absolute;
  left: 40px;
}

.carousel-button-right {
  position: absolute;
  right: 30px;
}

.carousel-image-btn {
  border: none;
  background: none;
  /*margin: 0;*/
  /*margin-right: 5%;*/
  /*margin-bottom: 10%;*/
  padding: 0;
  /*height: 100%;*/
  /*width: 100%;*/
}

.carousel-image-vod-btn {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.carousel-image-btn:focus,
.carousel-image-vod-btn:focus {
  outline: none;
}

/*.home .carousel-container,*/
/*.vod .carousel-container {*/
/*  margin-bottom: 50px;*/
/*}*/

/*.home .carousel-wide-container,*/
/*.vod .carousel-wide-container {*/
/*  margin-bottom: -150px;*/
/*}*/

/*.settings-list .modal-body {*/
/*  padding-left: 0;*/
/*  padding-right: 0;*/
/*}*/

/*.settings-list .btn-link {*/
/*  width: 100%;*/
/*  text-align: left;*/
/*  font-weight: 500;*/
/*  font-size: 22px;*/
/*  line-height: 27px;*/
/*  letter-spacing: 0.02em;*/
/*  text-transform: uppercase;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

.settings-list .card {
  margin-bottom: 30px;
}

.settings-list .card .card-header {
  white-space: nowrap;
}

.modal-body .settings-list {
  width: max-content;
  /*margin: auto;*/
  /*padding-top: 50px;*/
}

.close-btn {
  /*padding-right: 20px;*/
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin-right: 16px;
  margin-top: 16px;
  background: none;
  border: none;
}

.modal-body .card .card-header .btn .dropdown-icon {
  display: inline-block;
  padding-right: 10px;
}

.modal-body {
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  padding-top: 2em;
  /*padding-bottom: 32px;*/
}

.software-modal .modal-content .modal-body .software-content .software-logo {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.licences-modal .modal-content .modal-body {
  display: flex;
  justify-content: center;
}

.licences-modal .modal-content .modal-body .licences-content .card {
  padding-bottom: 10px;
}

.licences-modal .modal-content .modal-body .licences-content .card .card-header {
  font-size: 25px;
  padding-bottom: 10px;
}

.licences-modal .modal-content .modal-body .licences-content .card .card-body {
  font-size: 20px;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper-flexbox .avatar-card {
  flex: 0 0 auto;
}

.avatar-slider p {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.00625rem;
  padding-top: 30px;
  padding-bottom: 20px;
}

.avatar-slider .avatar-card {
  padding-right: 15px;
}

.avatar-slider button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
}

.avatar-slider button:focus {
  outline: none;
}

.privacy-modal .modal-body p {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.009375rem;
}

.privacy-modal .modal-body {
  padding-left: 30px;
  padding-right: 10px;
}

.indicators-row p {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.09375rem;
}

.indicators-row .col p,
.indicators-row .col img {
  display: inline;
}

.indicators-row {
  padding-left: 20px;
  padding-right: 0;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

.indicators-row .col {
  padding-left: 0;
  padding-right: 0;
}

.indicators-row img {
  padding-right: 8px;
}

.profiles-view {
  max-width: 100vw;
  margin-top: 0;
  height: 100vh;
}

.profiles-view .modal-title {
  text-align: center;
}

.profiles-view .modal-header > div {
  display: inline;
}

.profile-pin {
  max-width: 100vw;
  margin-top: 0;
  height: 100vh;
}

.profile-pin form {
  width: 50%;
  margin: 0 auto;
}

.search .grouped-text p {
  padding-top: 20px;
}

.search .form-control {
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 0 4px 4px 0 !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border: none;
  margin: 0 !important;
  padding-left: 14px;
  padding-right: 14px;
}

.search .search-btn {
  height: 100%;
  background-color: #FFFFFF;
  width: 50px;
  border-radius: 50px 0 0 50px;
}

.search input.form-control::placeholder {
  opacity: 0.4;
}

.search input.form-control:-ms-input-placeholder {
  opacity: 0.4;
}

.search input.form-control::-ms-input-placeholder {
  opacity: 0.4;
}

.epg-list {
  position: relative;
}

.avatar-card .avatar-image {
  padding: 0;
  width: 30px;
  height: 30px;
}

.avatar-slider .avatar-card > button {
  height: 80px;
  width: 80px;
  background-color: #828282;
  border-radius: 50%;
}

.avatar-slider .avatar-card > button.active {
  background-color: #21B14B;
}

/*.profiles-list .card .card-header {*/
/*  width: 320px;*/
/*}*/

.profiles-list .card .card-header .avatar-image-card {
  height: 4em;
  width: 4em;
  background-color: #828282;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.profiles-list .card .card-header .avatar-card {
  display: flex;
}

/*.profiles-list .card .card-header .avatar-name {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

.profile-pin .avatar-image-card {
  height: 80px;
  width: 80px;
  background-color: #828282;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.profile-pin .avatar-image {
  padding: 0;
  width: 30px;
  height: 30px;
}

.profile-edit .avatar-card {
  display: inline-block;
}

.avatar-slider .avatar-card-new {
  display: inline-block;
}

.profile-edit .ScrollbarsCustom-Content {
  white-space: nowrap;
}

.profile-edit .ScrollbarsCustom-Wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}

.subscriptions-modal-1 .indicators-row > div > p,
.subscriptions-modal-2 .indicators-row > div > p,
.subscriptions-modal-3 .indicators-row > div > p {
  display: inline;
}

.subscriptions-modal-1 .indicators-row > div,
.subscriptions-modal-2 .indicators-row > div,
.subscriptions-modal-3 .indicators-row > div {
  padding-right: 30px;
  padding-left: 10px;
}

/*.tv .row, .tv .shop {*/
/*  margin-bottom: 40px;*/
/*}*/

.suggestions {
  background-color: white;
  margin-top: 61px;
  position: absolute;
  width: 100%;
  border-radius: 27px;
  z-index: 11;
}

.suggestions > div {
  background-color: white;
  padding: 10px 64px 10px 64px;
  border-radius: 27px;
  color: black;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.009375rem;
}

.suggestions > div:hover {
  cursor: pointer;
}

.fade-in {
  animation: fadeIn ease 1;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.input-group-append {
  height: 24px;
  width: 24px;
  margin-left: -34px;
  background: url('../img/close-icon-search.svg');
  z-index: 10;
  margin-top: 15px;
  cursor: pointer;
}

.home .img-container {
  height: 100%;
}

.loader {
  animation-name: spinner;
  /*animation-duration: 1s;*/
  animation-iteration-count: infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-img {
  height: 120px;
  width: 120px;
  display: block;
  background: url('../img/spinner_sprites_small.png');
  animation: loader-animation-small 1s steps(24) infinite;
}

@keyframes loader-animation {
  to {
    background-position: -5760px 0px;
  }
}

@keyframes loader-animation-small {
  to {
    background-position: -2880px 0px;
  }
}

.language-modal .modal-header {
  margin-bottom: 0;
}

.language-modal .modal-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.lang-settings {
  padding-top: 10%;
}

.interface-lang {
  margin-left: 33%;
}

.interface-lang .title {
  display: flex;
  align-items: center;
}

/*.interface-lang .title > p {*/
/*  font-weight: bold;*/
/*  display: inline-block;*/
/*  line-height: 32px;*/
/*}*/

.interface-lang .title .flag {
  padding-right: 15px;
}

.interface-lang .options {
  padding-top: 5%;
}

option {
  background: #3C3C3C;
}

.interface-lang .options > button {
  background: none;
  color: #828282;
  border: none;
  text-transform: capitalize;
  width: 80px;
  height: 33px;
  border-radius: 62px;
  margin-left: 13px;
  margin-right: 13px;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.interface-lang .options .active {
  border: 2px solid #62E479 !important;
  color: #62E479;
}

.interface-lang .options > button:focus {
  outline: none;
}

.interface-lang .options > button:hover {
  border: 2px solid #828282;
  color: #62E479;
}

.lang .audio-lang {
  padding-top: 70px;
  margin-left: 33%;
}

.lang .language {
  margin-top: 24px;
  display: table;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

.lang .language > img {
  display: inline-block;
  padding-left: 8px;
}

.lang .language > p {
  display: inline-block;
  padding-left: 25px;
  white-space: nowrap;
}

.lang .language-title-text {
  font-weight: bold;
}

.lang .language-title {
  margin-bottom: 10px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.lang .language-title > img {
  padding-right: 17px;
}

.lang .subtitle-lang {
  padding-top: 70px;
  margin-left: 33%;
}

.back-btn {
  padding-left: 20px;
}

.modal-title .profiles-icons {
  padding-right: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider:not(.side) {
  background-color: #62E479;
}

input:focus + .slider:not(.side) {
  box-shadow: 0 0 1px #62E479;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.subtitle-disabled {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.subtitle-disabled .title {
  margin-left: 47px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

.subtitle-disabled .toggle {
  margin-left: 20px;
  display: flex;
}

.subtitle-disabled .toggle .switch {
  margin-bottom: 0;
}

.profiles-list .arrow-btn {
  position: relative;
}
  
.profiles-list .arrow-btn::after {
  content: url('../img/arrow-expand.svg');
  position: absolute;
  top: 20px;
  left: 300px;
}

.profiles-list .arrow-btn[aria-expanded="true"]::after {
  content: url('../img/arrow-collapse.svg');
}

.search-input-container {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 24px;
  margin-bottom: 1rem;
  background-color: #121212;
}

.search-input {
  width: 32%;
  min-width: 300px;
  height: 40px;
  padding-left: 40px;
  background-color: #3C3C3C;
  color: #E0E0E0;
  border: none;
  border-radius: 8px;
  background-image: url('../img/search-icon.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-right: 10px;
}

/*.smart-image-container {*/
/*  position: relative;*/
/*   */
/*}*/

.progress {
  position: absolute;
  width: 94%;
  left: 3%;
  bottom: 5%;
  height: 5px;
  background-color: white;
  opacity: 0.4;
  border-radius: 4px;
}

.progress-bar {
  /*background-color: white;*/
  z-index: 1;
  border-radius: 4px;
  background-color: #62E479;
  width: 34.1%;
  position: absolute;
  bottom: 5%;
  left: 3%;
  height: 5px;
  /*border-radius: 5px;*/
}

.home .home-vod {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.home .home-vod p {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.09375rem;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
}

.home .home-vod .line1 {
  top: 80%;
}

.home .home-vod .line2 {
  top: 90%;
  line-height: 15px;
}

.home .img-shadow {
  height: 100%;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
}

.vod .img-shadow {
  height: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.play .control-button-group {
  display: flex;
  align-items: center;
}

.play .resizing-group {
  position: absolute;
  right: 0;
}

.play .sound-group:hover .volumebar {
  display: block;
}

.sound-group {
  filter: brightness(60%);
}

.play .volumebar {
  display: none;
  padding: 0;
  margin: 0;
  width: 115px;
  height: 5px;
  border-radius: 4px;
  background: ghostwhite;
  box-shadow: none;
}

.play .rangeslider__fill {
  background-color: #62E479;
}

.play .rangeslider__handle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #21B14B;
  border: none;
}

.play .rangeslider__handle:focus {
  outline: none;
}

.play .rangeslider__handle:after {
  display: none;
}

.play .rangeslider__handle-tooltip {
  display: none;
}

.form-input-field {
  border-radius: 0 50px 50px 50px;
}

.img-container.\31 6_9_big {
  aspect-ratio: 16/9;
}

.img-container.\31 6_9_medium {
  aspect-ratio: 16/9;
}

.img-container.\32 _3_big {
  aspect-ratio: 2/3;
}

.img-container.\32 _3_medium {
  aspect-ratio: 2/3;
}

/*.img-container.row, .img-container.medium_16_9 {*/
/*  margin: 0 10px 0;*/
/*}*/

.section {
  /*margin-bottom: 20px;*/
  overflow: hidden;
}

/*ne 72, nes itemų marginai 10 px*/
.page-margins {
  margin-left: 72px;
  margin-right: 62px;
}

.season-section {
  overflow: hidden;
  width: 100%;
}

/*.live-status {*/
/*  background-color: gray;*/
/*  padding: 5px;*/
/*}*/

.undefined_screenwide_undefined {
  margin-bottom: -300px;
}

/*.home .homepage {*/
/*.home {*/
/*  margin-top: -152px;*/
/*}*/

/*.home .navbar {*/
/*  z-index: 1;*/
/*  background: none;*/
/*}*/

.smart-item-wide {
  /*background-image: linear-gradient(to bottom, rgba(31, 31, 31, 0.8) 15%, rgba(31, 31, 31, 0) 90%, rgba(31, 31, 31, 0.8) 95%, rgba(31, 31, 31, 1) 100%);*/
  /*display: flex;*/
  position: relative;
  width: 100%;
  padding-bottom: 40%;
  font-size: 16px;
  line-height: 24px;
}

.smart-item-wide .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(60%) blur(1px);
}

/*.smart-item-wide .content {*/
/*  width: 95%;*/
/*  margin: 170px 0 300px -95%;*/
/*  padding: 0;*/
/*  display: flex;*/
/*}*/

/*.smart-item-wide .content .poster {*/
/*  max-height: 500px;*/
/*  object-fit: cover;*/
/*}*/

/*.smart-item-wide .content .description {*/
/*  margin-left: 65px;*/
/*  width:40%;*/
/*}*/

.smart-item-wide .description {
  position: absolute;
  bottom: 44px;
  left: 72px;
  width: 30%;
  min-width: 600px;
}

.smart-item-wide .description.about-box {
  max-width: 100%;
  bottom: 80px;
  width: 100%;
}

.description.about-box .about-poster {
  max-width: 304px;
  border-radius: 8px;
}

.description.about-box .labels .about-provider {
  max-width: 112px;
  border-radius: 20px;
  padding: 8px 16px;
  margin-bottom: 16px;
  background-color: rgba(35, 35, 35, 0.8);
}

.smart-item-wide .description .about-provider {
  max-width: 104px;
  border-radius: 20px;
  padding: 8px 12px;
  margin-bottom: 10px;
  background-color: rgba(35, 35, 35, 0.8);
}

.crew-box {
  padding-left: 72px;
  padding-right: 72px;
  margin-bottom: 58px;
}

.crew-slider {
  padding-top: 15px;
}

.crew-slider .carousel-button-group {
  width: 100%;
  position: relative;
  z-index: unset;
  top: -100px;
}

.crew-slider .carousel-button-group .carousel-button.carousel-button-left {
  left: -60px;
}

.crew-slider .carousel-button-group .carousel-button.carousel-button-right {
  right: -20px;
}

.crew-slider .actor-container {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.crew-slider .actor-container > img {
  display: block;
  width: 115px;
  height: 115px;
  object-fit: cover;
  border-radius: 50%;
  margin: auto auto 18px;
}

.searchpage {
  margin: 0 5%;
  padding-bottom: 50px;
}

.searchpage .input {
  width: 40%;
  margin: auto auto 60px auto;
  position: relative;
}

.searchpage .input > p {
  color: #21B14B;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 3px;
  background-color: #1F1F1F;
  position: absolute;
  top: -11px;
  left: 23px;
}

.searchpage .input > input {
  width: 100%;
  background: none;
  border: 2px solid #21B14B;
  border-radius: 30px;
  height: 59px;
  outline: none;
  padding-left: 26px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

.searchpage .content {
  padding: 0;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.searchpage .content > p {
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  width: 450px;
  margin: 40px auto 0 auto;
}

@media screen and (min-width: 500px) {
  .search-img-wrapper {
    width: 100%;
  }
}

@media screen and (min-width: 725px) {
  .search-img-wrapper {
    width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .search-img-wrapper {
    width: 33.3%;
  }
}

@media screen and (min-width: 1366px) {
  .search-img-wrapper {
    width: 25%;
  }
}

@media screen and (min-width: 1600px) {
  .search-img-wrapper {
    width: 20%;
  }
}

@media screen and (min-width: 2560px) {
  .search-img-wrapper {
    width: 16.7%;
  }
}

@media screen and (min-width: 3840px) {
  .search-img-wrapper {
    width: 10%;
  }
}

.search-img-wrapper {
  position: relative;
}

.age-group > h5 {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 25px;
  padding-left: 20px;
}

.age-group .container {
  display: block;
  position: relative;
  padding-left: 100px;
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.age-group .container > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.age-group .container .checkmark {
  position: absolute;
  top: -4px;
  left: 10px;
  height: 26px;
  width: 26px;
  background-color: #000000;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
}

.age-group .container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.age-group .container > input:checked ~ .checkmark:after {
  display: block;
}

.age-group .container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

.age-group > hr {
  border: 1px solid #4D4D4D;
  margin: 0 0 20px 0;
}

.shimmers {
  width:400px;
  height:200px;
  background-color: #cfff;
}

.line-shimmer {
  display: inline-block;
  height: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
  width: 400px;
  background-color: #f6f7f9;
  float: left;
  clear: both;
  background-image: linear-gradient(90deg,#f6f7f9 0,#e9ebee 20%,#f6f7f9 40%,#f6f7f9);
  background-size: 99% 100%;
  background-repeat: no-repeat;
  animation: shimmer 1s linear 1ms infinite backwards;
}

@keyframes shimmer {
  0% {
    background-position: 500% 100%;
  }

  100% {
    background-position: 10000% 100%;
  }
}

.control-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: transparent;
  border-color: transparent;
  width: 1em;
  height: 1em;
}

a.disabled {
  pointer-events: none;
  background-color: transparent;
}

.product-label {
  position: absolute;
  bottom: 0.5em;
  /*background-color: black;*/
  opacity: 0.7;
  width: 100%;
  color: white;
  /*padding: 5px;*/
  /*text-align: center;*/
}

.channel-item {
  min-height: 185px;
}

.product-logo {
  width: 50%;
  transform: translate(50%, 0);
  background: rgba(0, 0, 0, 0.75);
  border-radius: 0 0 8px 8px;
  padding: 5px;
  position: absolute;
  text-align: center;
  z-index: 1;
}

.product-detail {
  padding-left: 3.3%;
}

.product-item {
  position: relative;
  top: -37px;
}

.center-content {
  /*transform-origin: 50% 50%;*/
  position: relative;
  top: 50%;
  text-align: center;
}

@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {

  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background-image: url('../img/arrow-expand.svg');
  background-repeat: no-repeat;
  /*background-position: 50%;*/
  background-size: 28px 28px;
  width: 72px;
  height: 72px;
  background-color: #424242;
  border-radius: 50%;
  top: 50%;
  right: 50%;
  opacity: 1;
  border-style: solid;
  border-color: gray;
  border-width: 2px;
}

.carousel-control-next-icon {
  transform: rotate(-90deg);
}

.carousel-control-prev-icon {
  transform: rotate(90deg);
}

.product-box h3 {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0;
}

.button {
  position: relative;
  border-radius: 40px;
  border: none;
  padding: 8px 16px 8px 16px;
  font-weight: 500;
  font-size: 0.95rem;
  text-align: center;
}

.btn-icon {
  height: 1.2rem;
  margin-right: 0.5rem;
}

button:disabled {
  background-color: rgba(66, 66, 66, 0.8);
  color: #7a7a7a;
  cursor: not-allowed;
}

button:disabled:hover {
  background: rgba(255, 255, 255, 0.12);
  color: #7a7a7a;
}

.button:focus {
  border: none;
  outline: none;
}

.btn-primary {
  background: rgba(29, 127, 43, 1);
  color: white;
}

.btn-primary:focus {
  border: none;
  outline: none;
  background: rgba(52, 162, 65, 1);
}

.btn-primary:hover {
  background: rgba(52, 162, 65, 1);
  color: white;
}

.btn-primary-bright {
  background: rgba(52, 162, 65, 1);
  text-align: center;
  color: white;
}

.btn-primary-bright:hover {
  background-color: #2e953b;
  color: white;
}

.nav-link {
  text-align: center;
  color: white;
  border-radius: 20px;
}

.navbar-nav .nav-link {
  color: rgba(152, 155, 151, 1);
}

.navbar-nav .nav-link.active {
  color: #21B14B;
}

.navbar-nav .nav-link.btn-navbar, .nav-link.btn-navbar-subscribe {
  color: white;
}

.navbar-nav .nav-link.btn-navbar.btn-circle {
  padding: 7px;
}

.navbar-nav .nav-link.btn-navbar.btn-circle.active {
  background: #252525;
}

.navbar-nav .nav-link.btn-navbar.active {
  background: rgba(33, 177, 75, 1);
  color: white;
}

.btn-navbar.active img {
  /*filter: brightness(0) invert(1);*/
  filter: invert(44%) sepia(86%) saturate(381%) hue-rotate(76deg) brightness(100%) contrast(92%);
}

.btn-secondary {
  color: #fff;
  background-color: #3C3C3C;
  /*border-color: #6c757d;*/
}

.btn-secondary.active {
  color: #fff;
  background-color: #5B5B5B;
}

/*.btn-secondary {*/
/*  color: #fff;*/
/*  background-color: #3C3C3C;*/
/*  border-color: #3C3C3C;*/
/*}*/

.btn-secondary:hover {
  color: #fff;
/*  background-color: #4B4B4B;*/
/*  border-color: #4B4B4B;*/
}

.btn-outline-secondary {
  border-radius: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(143, 143, 143, 1);
  color: white;
  background-color: transparent;
}

.btn-outline-pressed {
  border-radius: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(143, 143, 143, 1);
  color: white;
  background: rgba(255, 255, 255, 0.12);
}

.btn-loading {
  position: relative;
}

.btn-loading .spinner {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  /*border: 2px solid #888;*/
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  visibility: hidden;
}

.btn-loading.loading {
  padding-left: 36px;
}

.btn-loading.loading .spinner {
  visibility: visible;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.btn-outline-secondary:hover {
  background: rgba(255, 255, 255, 0.08);
  /*background: linear-gradient(0deg, #8F8F8F, #8F8F8F),*/
  /*linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));*/
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: none;
}

.btn-lg {
  padding: 8px 24px 8px 24px;
  font-weight: 500;
  font-size: 1rem;
  /*line-height: 24px;*/
  /*border: none;*/
}

/*.products-group-box h4 {*/
h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 2px;
}

.product-box {
  min-width: 342px;
  max-width: 342px;
  padding: 24px;
  margin-right: 17px;
  /*background: rgba(35, 35, 35, 0.96);*/
  background: #2e2e2e;
  border-radius: 8px;
  color: rgba(152, 155, 151, 1);
  position: relative;
}

.product-box.promoted {
  border-radius: 0 8px 8px 8px;
}

.promoted {
  position: relative;
}

th.promoted::before {
  content: "";
  position: absolute;
  top: -7px;
  bottom: -7px;
  left: 0;
  width: 100%;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

td.promoted::before {
  content: "";
  position: absolute;
  top: -7px;
  bottom: -7px;
  left: 0;
  width: 100%;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

tfoot td.promoted::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-radius: 0 0 8px 8px;
  /*pointer-events: none;*/
}

#compare-table {
  border-spacing: 18px 0;
  border-collapse: inherit;
  table-layout: fixed;
  width: 730px;
  /*margin: 0 auto;*/
}

#compare-table th {
  vertical-align: top;
  padding: 19px 9px 11px;
}

#compare-table th,
#compare-table td {
  width: 146px;
}

#compare-table th:first-child,
#compare-table td:first-child {
  width: 282px;
  position: sticky;
  left: 18px;
  z-index: 2;
  /*background: rgba(35, 35, 35, 0.96);*/
  /*overflow: visible;*/
}

#compare-table td:first-child::before,
#compare-table th:first-child::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(35, 35, 35, 0.96);
  z-index: -1;
  height: 39px;
}

#compare-table thead td:first-child::before,
#compare-table thead th:first-child::before {
  height: calc(100% + 30px);
}

#compare-table tfoot td:first-child::before,
#compare-table tfoot th:first-child::before {
  height: 73px;
}

#compare-table td::after,
#compare-table th::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: -15px;
  right: -3px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  z-index: 1;
  background: rgba(35, 35, 35, 0.96);
}

#compare-table td {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
}

#compare-table tfoot td::after {
  content: none;
}

#compare-table tfoot td {
  padding-top: 16px;
  padding-bottom: 16px;
}


.table > :not(caption) > * > * {
  padding: .5rem .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: none;
}

.promoted-compare-box {
  position: absolute;
  top: -18px;
  left: 0;
  width: 100%;
  height: 32px;
  color: rgba(29, 127, 43, 1);
  background-color: white;
  border-radius: 8px 8px 0 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding-top: 7px;
  text-align: center;
}

.product-box.choice {
  background: #2e2e2e;
  margin-right: 63px;
  margin-bottom: 24px;
  padding-bottom: 22px;
}

.product-box:hover {
  /*background-color: #3e3e3e;*/
  background-color: rgba(62, 62, 62, 0.96);
}

.product-box-head {
  margin-bottom: 12px;
}

.product-box-price {
  margin-bottom: 12px;
}

.product-box-price span {
  font-size: 20px;
  line-height: 24px;
}

.product-box-price .discounted {
  font-size: 18px;
  line-height: 24px;
  text-decoration: line-through;
  margin-bottom: 17px;
}

.product-box h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #21B14B;
  margin-bottom: 0;
}

.product-box h2 {
  font-weight: 500;
  font-size: 38px;
  line-height: 48px;
  color: white;
  margin-bottom: 0;
  display: inline;
}

/*.product-box hr {*/
hr {
  margin-bottom: 4px;
  margin-top: 4px;
  /*color: rgba(255, 255, 255, 0.1);*/
  background-color: inherit;
  border: 1px solid rgba(255, 255, 255, 0.1);
  opacity: inherit;
}

.product-box .content {
  margin-bottom: 19px;
  padding-left: 0;
}

.product-box .content div {
  margin-bottom: 13px;
}

.product-box .content h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: rgba(152, 155, 151, 1);
}

.compare-box .accordion-button {
  width: auto;
  color: white;
  background-color: transparent;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  padding: 0;
}

.compare-box .slider {
  background-color: rgba(66, 66, 66, 0.8);
}

.compare-box .slider:before {
  background-color: #989B97;
}

.compare-box .accordion-button:focus {
  box-shadow: none;
}

.compare-box .accordion-button::after {
  background-image: url('../img/arrow-expand.svg');
  margin-left: 26px;
  width: 25px;
  height: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.square-container {
  flex-basis: 80px;
  flex-shrink: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 4px;
  background-color: rgba(66, 66, 66, 0.8);
  border-radius: 8px;
}

.square-image {
  max-width: calc(100% - 10px);
  max-height: 29px;
  display: block;
  margin: auto;
}

.square-product-container {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.square-product-image {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.channel-list .active {
  border-width: 1px;
  border-style: solid;
  border-color: #34A241;
  background-color: #29362B;
}

.channel-list li:hover {
  background-color: #343434;
}

.channel-list li {
  list-style: none;
  padding-top: 10%;
  padding-bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background-color: rgba(66, 66, 66, 0.8);*/
  border-radius: 8px;
}

.channel-list img {
  max-width: 80%;
  max-height: 48px;
  display: block;
  margin: auto;
}

.close-icon {
  width: 24px;
}

b {
  font-weight: 500;
}

strong {
  font-weight: 700;
}

.progress-circle {
  margin-right: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
}

.progress-circle.active {
  background-color: rgba(33, 177, 75, 1);
}

.supporting-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(152, 155, 151, 1);
  padding-left: 16px;
  padding-top: 4px;
}

.circular-progress {
  width: 116px;
  height: 116px;
  background-image: url("../img/circular-progress-indicator-dark.svg");
  animation: spinner 2s linear infinite;
}

.tags-box {
  color: rgba(255, 255, 255, 0.6);
  /*font-size: 13px;*/
  /*line-height: 24px;*/
  font-weight: 400;
}

.tags-box div {
  padding-left: 6px;
}

.tags-box div span {
  padding-right: 6px;
}

.imdb-box {
  background-color: #E6B91E;
  border-radius: 2px;
  width: 65px;
  height: 24px;
  color: #232323;
  font-size: 14px;
  padding-left: 6px;
}

.labels .imdb-box img, .imdb-box img {
  vertical-align: unset;
  padding-right: 5px;
  border-radius: unset;
  position: relative;
  top: 1px;
}

.age-box {
  border-style: solid;
  border-width: 2px;
  border-radius: 2px;
  height: 24px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 3px;
}

.audio-tracks-box {
  text-transform: uppercase;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 2em;
}

.smart-row {
 margin-top: 3%;
 margin-left: 72px;
 padding-right: 72px;
}

.smart-row p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-shadow: 2px 2px 2px #000;
  margin-bottom: 16px;
}

.smart-row-item {
  position: relative;
  z-index: 1;
  margin-right: 5%;
  margin-bottom: 5%;
}

.smart-row-item.active {
  border: 1px solid white;
  border-radius: 8px;
}

.hovered-content-header {
  font-size: 1.1rem;
}

.hovered-content {
  display:none;
  position: absolute;
  top: 0;
  width: 101%;
  height: 100%;
  background-color: #343434;
  padding: 18px;
  border-radius: 8px;
  font-size: 1rem;
  z-index: 2;
  /*line-height: 1rem;*/
}

.carousel-image-btn:hover + .hovered-content {
  position: absolute;
  display: block;
  transform: translateX(98%);
  transition: transform 0.5s ease-out;
}

.popover-arrow {
  position: absolute;
  left: -2.5%;
  border-right: 20px solid #343434;
  border-bottom: 20px solid transparent;
  transform: rotate(-135deg);
}

.smart-row-img {
  height: auto;
  border-radius: 8px;
  max-width: 100%;
}

.smart-row-logo {
  max-height: 42px;
  max-width: 100%;
}

.smart-row-item .info-box {
  /*position: relative;*/
  width: 100%;
  margin-top: 8px;
  align-items: center;
}

.smart-row-item .info-box .logo-box {
  width: 30%;
  padding-left: 10px;
  padding-right: 10px;
}

.smart-row-item .info-box .labels {
  width: 70%;
}

.smart-row-item .info-box .labels, .epg-detail .labels {
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 0;
  text-shadow: none;
  color: white;
}

.labels .episode-time {
  font-size: 0.9em;
  margin-top: 0.4em;
  color: #989B97;
}

.about-box .labels {
  padding-left: 34px;
  width: 100%;
}
/*#2E2E2E*/

/*@keyframes spinner {*/
/*  0% { transform: rotate(0deg); }*/
/*  100% { transform: rotate(360deg); }*/
/*}*/

/*@keyframes spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

.genres-list {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.genres-list li {
  margin-right: 10px;
  display: inline-block;
}

.btn-cube {
  width: 6em;
  height: 6em;
  align-items: center;
  /*justify-content: center;*/
  flex-direction: column;
  display: flex;
  padding-top: 1em;
  border-radius: 8px;
}

.btn-cube:hover {
  background-color: #343434;
}

.similar-container {
  margin-right: -2.5%;
}

.similar-container .search-img-wrapper {
  width: 50%;
}

ul.dropdown-menu {
  background-color: black;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  width: max-content;
  min-width: 5em;
}

ul.dropdown-menu li {
  padding: 0.75em 1.5em;
}

ul.dropdown-menu li a {
  color: white;
}

ul.dropdown-menu li .active {
  color: #21B14B;
}

ul.dropdown-menu li:hover {
  background-color: #252525;
}

.lazy-load-image-background {
  width: 100%;
  border-radius: 8px;
}

.lazy_2_3_medium_group {
  height: 509px;
}

.lazy_channel, .lazy_product {
  height: 152px;
}

.lazy_2_3_big, .lazy_2_3_medium {
  padding-top: 140%;
}

.lazy_16_9_big, .lazy_16_9_medium {
  padding-top: 56.25%;
}

footer {
  padding-left: 3.6%;
  padding-right: 3.6%;
  margin-top: 2rem;
  font-size: 1rem;
}

.footer-content {
  margin-bottom: 39px;
  display: flex;
}

.footer-bottom {
  font-weight: 500;
  color: #989B97;
  margin-bottom: 42px;
}

.valid-until {
  position: absolute;
  width: 11%;
  top: 7%;
  left: 3.8%;
}

.overflow-auto::-webkit-scrollbar {
  width: 3px;
  height: 8px;
  background-color: transparent;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background: gray;
}